<template>
  <b-overlay :show="is_busy" rounded="sm">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">

          <div class="mb-2">
            <h2><strong>Change Password</strong></h2>
          </div>
          <div class="card">
            <div class="card-body">
              <b-form @submit.prevent="update()">
                <b-form-group class="">
                  <label>Old Password</label>
                  <b-form-input type="password" v-model="password.oldPassword" size="sm"></b-form-input>
                </b-form-group>
          
                <b-form-group class="">
                  <label>New Password</label>
                  <b-form-input type="password" v-model="password.newPassword" size="sm"></b-form-input>
                </b-form-group>

                <b-form-group class="">
                  <label>Confirm New Password</label>
                  <b-form-input type="password" v-model="password.confirmNewPassword" size="sm"></b-form-input>
                </b-form-group>

                <button type="submit" class="btn btn-success btn-lg btn-block">Proceed</button>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
  import axios from 'axios';

  export default {
 
    data() {
      return {
        password: {
          user_id: '',
          oldPassword: '',
          newPassword: '',
          confirmNewPassword: '',
        },

        user: '',
        is_busy: false,
      }
    },

    created() {
      this.loadSettings();
    },

    methods: {
      loadSettings(){
        if(this.is_busy) return;
        this.is_busy = true;

        let localUser = localStorage.getItem("user")
        if (localUser){
          this.user = JSON.parse(localUser).data.user;
          this.password.user_id = this.user.id;
        }
        this.is_busy = false;
      },


      update(){
        if(this.password.oldPassword == '' || this.password.confirmNewPassword == '' || this.password.newPassword == ''){
          Swal.fire(
            "Error!",
            'Please fill all the form fields',
            "error"
          );
        }
        else {
          if(this.password.confirmNewPassword != this.password.newPassword){
            Swal.fire(
              "Error!",
              'New Password must be the same with the confirm password',
              "error"
            );
          }
          else {
            if(this.is_busy) return;
            this.is_busy = true;
            axios.post('/password', this.password)
            .then(()=>{
              Swal.fire(
                "Success!",
                'Password changed successful',
                "success"
              );
              this.$router.push({path: '/'});
            })
            .catch((err)=>{
              Swal.fire(
                "Error!",
                err.response.data.data.error,
                "error"
              );
              
            })
            .finally(() => {
                this.is_busy = false;
            }); 
          }
        }
      },
    }, 
  }
</script>
